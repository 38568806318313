import { createRef, lazy } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

// Mui
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// Settings
import versionObj from "./version.json";

// Styles
import "./scss/App.scss";

// Custom components
import NavigationScroll from "./components/layout/NavigationScroll";
import Loadable from "./components/general/Loadable";
import themes from "./themes";

// Utils
import settings from "./settings.json";

// Hooks
import { useFetchUser } from "./hooks/useFetchUser";

// Interfaces
import { role } from "./interfaces/Base";

// Pages
const MainLayout = Loadable(lazy(() => import("./components/layout/MainLayout")));
const Login = Loadable(lazy(() => import("./components/pages/auth/Login")));
const Usages = Loadable(lazy(() => import("./components/pages/usages/Usages")));
const Usage = Loadable(lazy(() => import("./components/pages/usages/Usage")));
const User = Loadable(lazy(() => import("./components/pages/users/User")));
const Users = Loadable(lazy(() => import("./components/pages/users/Users")));
const Stats = Loadable(lazy(() => import("./components/pages/stats/Stats")));

const PrivateRoute = ({ title, rolesAllowed = [], children }: any) => {
    const { currentUser } = useFetchUser();

    document.title = `${title} - ${settings.app.title}`;

    // Hack-ish code to return you to the page you were before refresh
    if (!currentUser) {
        if (!window.location.href.includes("/login")) {
            return <Login />;
        }
    }

    return currentUser && rolesAllowed.some((r: string) => currentUser.roles.includes(r as role)) ? children : <Navigate to="/login" />;
};

// const PublicRoute = ({ title, children }: any) => {
//     document.title = `${title} - ${settings.app.title}`;
//     return children;
// };

const App: React.FC<any> = () => {
    console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");

    const snackbarRef: any = createRef();

    return (
        <StyledEngineProvider injectFirst>
            <SnackbarProvider
                style={{ margin: 2 }}
                ref={snackbarRef}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                autoHideDuration={2000}
            >
                <ThemeProvider theme={themes()}>
                    <CssBaseline />
                    <NavigationScroll>
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path="users"
                                    element={
                                        <PrivateRoute title="Utilisateurs" rolesAllowed={settings.app.roles}>
                                            <MainLayout>
                                                <Users />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="user"
                                    element={
                                        <PrivateRoute title="Utilisateur" rolesAllowed={settings.app.roles}>
                                            <MainLayout>
                                                <User />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                >
                                    <Route
                                        path=":id"
                                        element={
                                            <PrivateRoute title="Utilisateur" rolesAllowed={settings.app.roles}>
                                                <MainLayout>
                                                    <User />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    ></Route>
                                </Route>

                                <Route path="usage">
                                    <Route
                                        path=":id"
                                        element={
                                            <PrivateRoute title="Usage" rolesAllowed={settings.app.roles}>
                                                <MainLayout>
                                                    <Usage />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="usages"
                                    element={
                                        <PrivateRoute title="Usages" rolesAllowed={settings.app.roles}>
                                            <MainLayout>
                                                <Usages />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="stats"
                                    element={
                                        <PrivateRoute title="Statistiques" rolesAllowed={settings.app.roles}>
                                            <MainLayout>
                                                <Stats />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="*" element={<Login />} />
                            </Routes>
                        </BrowserRouter>
                    </NavigationScroll>
                </ThemeProvider>
            </SnackbarProvider>
        </StyledEngineProvider>
    );
};

export default App;
