import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

// Interfaces
import { User } from "../interfaces/User";

// Firebase
import { auth, firestore } from "../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";

const initState = {
    currentUser: null,
    fetchingUser: true,
    loading: false,
    // eslint-disable-next-line
    setLoading: (value: any) => {
        //
    },
};

const useFetchUserImpl = () => {
    // States
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [fetchingUser, setFetchingUser] = useState<boolean>(true);

    useEffect(() => {
        let unsub: any = null;

        auth.onAuthStateChanged(async res => {
            if (res) {
                setFetchingUser(true);

                unsub = onSnapshot(doc(firestore, "users", res.uid), doc => {
                    const data = doc.data() as User;

                    setCurrentUser(data);
                    setFetchingUser(false);
                });
            } else {
                setCurrentUser(null);
                setFetchingUser(false);
            }
        });

        return () => {
            typeof unsub === "function" && unsub();
        };
    }, []);

    return { currentUser, fetchingUser, loading, setLoading };
};

export const useFetchUser = singletonHook(initState, useFetchUserImpl);
